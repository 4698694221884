<template>
    <main>
        <div>
            <div class="card-header bg-light "><span><i class="fa fa-syringe"> </i> Complicaciones Identificadas</span> </div>
            <div class="card-body">
                <div class="form-row">
                    <div class="form-group col-md-12">
                        <table class="table table-striped table-sm table-bordered font-size-12">
                            <tr v-for="item in data" :key="item.id">
                                <th>{{item.complicaciones.nombre}}</th>
                                <td>{{item.detalle}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
export default {
    props:['idHistoria','data'],
}
</script>
<style scoped>
.font-size-12 {
  font-size: 12px;
}
</style>