<template>
    <main>
        <div v-if="data.formula==='COCKROFT-GAULT'">
            <div class="card-header bg-light "><span><i class="fa fa-file-medical"> </i> Tasa Filtracion Glomerular</span> </div>
            <div class="card-body">
                <h6 class="italic mb-2">Estimación Filtrado Glomerular (Cockcroft y Gault)</h6>
                <hr>
                <div class="row mt-2">     
                    <div class="col-6">Género: {{data.genero}}</div>
                    <div class="col-6">Edad: {{ data.edad }}</div>
                    <div class="col-6">Creatinina Sérica: {{data.creatinina}}</div>
                </div>
            </div>
        </div>  
        <div v-else>
            <div class="card-header bg-light "><span><i class="fa fa-file-medical"> </i> Tasa Filtracion Glomerular</span> </div>
            <div class="card-body">
                <h6 class="italic mb-2">Estimación Filtrado Glomerular (Schwartz)</h6>
                <hr>
                <div class="row mt-2">
                    <div class="col-6"> Grupo poblacional: {{ poblationalGroupLabel }} </div>
                    <div class="col-6">Talla: {{ data.talla }}</div>
                    <div class="col-6">Creatinina Sérica: {{data.creatinina}}</div>
                </div>
            </div>
        </div>
        <div class="list-group mt-2 col-lg-12 col-md-12 col-sm-12">
            <div class="list-group-item">
                <span class="font-weight-bolder badge badge-light badge-marketing mx-2">Resultado TFG : {{data.puntaje}} mL/min</span>
                <span :class="erc.class">({{erc.title}}) - {{ erc.text }}</span>
            </div>
        </div>  
    </main>
</template>
<script>
export default {
    props: ['idHistoria','idUsuario','data'],
    computed:{
        erc: function(){
            const estadios=[
                {
                    start:90,
                    end:99999,
                    title:'ERC en etapa 1',
                    text:'Daño renal leve',
                    class:'badge badge-success badge-marketing'
                }, 
                {
                    start:60,
                    end:89.99,
                    title:'ERC en etapa 2',
                    text:'Daño renal leve',
                    class:'badge badge-success badge-marketing'
                },
                {
                    start:45,
                    end:59.99,
                    title:'ERC en etapa 3a',
                    text:'Daño renal leve o moderado',
                    class:'badge badge-warning badge-marketing'
                    
                },
                {
                    start:30,
                    end:44.99,
                    title:'ERC en etapa 3b',
                    text:'Daño renal leve o moderado',
                    class:'badge badge-warning badge-marketing'
                },
                {
                    start:15,
                    end:29.99,
                    title:'ERC en etapa 4',
                    text:'Daño renal moderado o severo',
                    class:'badge badge-orange badge-marketing'
                },
                {
                    start:0,
                    end:15.99,
                    title:'ERC en etapa 5',
                    text:'Daño renal severo',
                    class:'badge badge-danger badge-marketing'
                },
            ]

            if(this.data){
                const stage = estadios.find(e=>this.data.estadio===e.title);
                
                return stage || {
                    start: 0,
                    end: 0,
                    title: 'Clasificación no disponible',
                    class: 'badge badge-light badge-marketing',
                    text: 'sin clasificación'
                };
            }

            return {
                start: 0,
                end: 0,
                title: 'Cargando clasificacion',
                class: 'badge badge-light badge-marketing',
                text:'sin clasificacion'
            };
        },
        poblationalGroupLabel() {
            const groupLabels = {
                low_weight_newborn: "Recien Nacido de Bajo Peso",
                newborn_infant: "Recien nacido de peso normal y lactante",
                boys_and_girls: "Niños y Niñas de 1 a 13 años",
                man: "Varon mayor a 13 Años",
                woman: "Mujer mayor a 13 Años"
            };

            return groupLabels[this.data.poblational_group] || "Grupo no identificado";
        }
    }
}
</script>