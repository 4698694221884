<template>
    <main>
        <div>
            <div class="card-header bg-light "><span><i class="fa fa-syringe"> </i> Tipo de Patologia</span> </div>
            <div class="card-body">
                <div class="form-row">
                    <div class="form-group col-md-12">
                        <table class="table table-bordered table-striped">
                            <tr>
                                <th>Patologia</th>
                                <th>{{data.respuesta}}</th>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
export default {
    props:['idHistoria','finalizada','data']
}
</script>

