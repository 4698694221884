<template>
    <div class="card shadow-none mt-2">
        <div class="card-body">
            <Component :is="tfgFormula" v-bind="params"></Component>
        </div>
    </div>
</template>
<script>
import {isEmpty} from "lodash";
import dayjs from 'dayjs';
import cockroftGault from "./cockroftGault.vue";
import schwartz from "./schwartz.vue";
import examenFisicoService from "../../../../services/examenFisicoService"
export default {
    props: ['idHistoria','finalizada','idUsuario','sexo','fecha_nacimiento'],
    data(){
        return {
            examenFisico:{}
        }
    },
    components: {
        cockroftGault,
        schwartz
    },
    methods:{
        async getExamenFisico(){
            const response = await examenFisicoService.showByHc(this.idHistoria);
            this.examenFisico = response.data;
        }
    },
    computed : {
        edad_u : function(){
            if(isEmpty(this.fecha_nacimiento)) return 0;
            return dayjs().diff(this.fecha_nacimiento, 'year');
        },
        tfgFormula: function(){
            if(this.edad_u >= 18) return 'cockroftGault';
            return 'schwartz';
        },
        params: function(){
            return {
                idHistoria: this.idHistoria,
                finalizada: this.finalizada,
                idUsuario: this.idUsuario,
                sexo: this.sexo,
                edad: this.edad_u,
                fecha_nacimiento: this.fecha_nacimiento,
                peso:this.examenFisico.peso?this.examenFisico.peso:0,
                talla:this.examenFisico.talla?this.examenFisico.talla:0,
            }
        },
    },
    async created(){
        await this.getExamenFisico();
    }
}
</script>