<template>
    <div>
        <h6 class="italic mb-2">Estimación Filtrado Glomerular (Cockcroft y Gault)</h6>
        <hr>
        <div class="card mt-2 shadow-none"  v-if="isLoading">
            <div class="card-body">
                <content-placeholders :rounded="true">
                <content-placeholders-heading :img="false" />
                <content-placeholders-text :lines="3" />
                </content-placeholders>
            </div>
        </div>
        <div class="row mt-2" v-else>
            <div class="form-group col-lg-4 col-md-12 col-sm-12">
                <label class="font-weight-bolder">Género</label>
                <select class="form-control" v-model="genero" :class="{'is-invalid':$v.genero.$error && $v.genero.$dirty}">
                    <option value="F">F</option>
                    <option value="M">M</option>
                </select>
            </div>
            <div class="form-group col-lg-4 col-md-12 col-sm-12">
                <label class="font-weight-bolder">Edad</label>
                <input type="number" class="form-control" v-model="edad_u" disabled :class="{'is-invalid':$v.edad_u.$error && $v.edad_u.$dirty}">
            </div>
            <div class="form-group col-lg-4 col-md-12 col-sm-12">
                <label class="font-weight-bolder">Creatinina Sérica (mg/dl)</label>
                <input type="number" class="form-control" v-model="creatinina" :class="{'is-invalid':$v.creatinina.$error && $v.creatinina.$dirty}">
            </div>
            <div class="form-group col-lg-4 col-md-12 col-sm-12">
                <label class="font-weight-bolder">Peso</label>
                <input type="number" class="form-control" v-model="peso" disabled :class="{'is-invalid':$v.peso.$error && $v.peso.$dirty}">
            </div>
       
       
           <div class="list-group mt-4 col-lg-8 col-md-12 col-sm-12">
                <div class="list-group-item">
                    <span class="font-weight-bolder badge badge-light badge-marketing mx-2">Resultado TFG : {{this.puntaje}}</span>
                    <span :class="erc.class">({{erc.title}}) - {{ erc.text }}</span>
                </div>
           </div>
            <div class="mt-3">
                    <button class="btn btn-secondary mx-2 btn-sm" @click="calcular();">Calcular</button>
                    <button class="btn btn-success btn-sm" @click="guardar();">Guardar</button>
            </div>
        </div>
    </div>
    
    </template>
    <script>
    import tazaFiltracionGlomerularService from '../../../../services/tazaFiltracionGlomerularService';
    import {cockroftGault} from '../../../../utils/utils';
    import {isEmpty} from "lodash";
    import Toast from '../../../../components/common/utilities/toast';
    import dayjs from 'dayjs';
    import {required} from "vuelidate/lib/validators";
    
    export default {
        props: ['idHistoria','finalizada','idUsuario','sexo','fecha_nacimiento','peso'],
        data() {
            return {
                genero:'',
                puntaje:'',
                creatinina:'',
                id_tfg:'',
                isLoading:true
            }
        },
        validations(){
            return {
                genero: {required},
                creatinina: {required},
                edad_u:{required},
                peso:{required}
            }
        },
        methods: {
            async calcular(){
                try {
                    this.$v.$touch();
                    if (this.$v.$invalid) return;

                    const gender = this.genero === 'F' ? 'female' : 'male';
                    const tfg = cockroftGault(gender, this.edad_u,this.peso,this.creatinina);
                    this.puntaje=tfg;
        
                } catch (e) {
                    console.error(e);
                    this.LoaderSpinnerHide();
                     Toast.fire({
                        icon: 'error',
                        title: 'Ocurrio un error al procesar la solicitud'
                    });
                }
            },
            async guardar(){
                try {
                    this.$v.$touch();
                    if (this.$v.$invalid) {
                        Toast.fire({
                            icon: 'error',
                            title: 'Verifique los campos'
                        });
                        return
                    }

                    if(this.puntaje===''){
                        Toast.fire({
                            icon: 'error',
                            title: 'Verifique el puntaje'
                        });
                        return;
                    }

                    let tfg={
                        id_historia: this.idHistoria,
                        genero:this.genero,
                        edad:this.edad_u,
                        creatinina:this.creatinina,
                        peso: this.peso,
                        formula : 'COCKROFT-GAULT',
                        puntaje:this.puntaje,
                        estadio:this.erc?this.erc.title:'',
                        clasificacion_estadio:this.erc?this.erc.text:'',
                    }
    
                    this.LoaderSpinnerShow();
    
                    if(this.id_tfg!==''){
                        tfg['id']=this.id_tfg;
                        await tazaFiltracionGlomerularService.update(tfg); 
                    }else{
                        await tazaFiltracionGlomerularService.store(tfg); 
                        const response=await tazaFiltracionGlomerularService.showByHc(this.idHistoria);
                        this.id_tfg=response.data.id;
                    }
                    
                    
                    this.LoaderSpinnerHide();
                    
                    Toast.fire({
                        icon: 'success',
                        title: 'Datos guardados con exito'
                    });
    
                } catch (e) {
                    console.error(e);
                    this.LoaderSpinnerHide();
                    Toast.fire({
                        icon: 'error',
                        title: 'Ocurrio un error al procesar la solicitud'
                    });
                }
            }
        },
        async created(){
            this.isLoading = true;
            this.genero=this.sexo;
            
            if(this.finalizada==='N'){
                const response=await tazaFiltracionGlomerularService.showByHc(this.idHistoria);
                if(!isEmpty(response.data)){
                   
                    this.creatinina=response.data.creatinina;
                    this.puntaje=response.data.puntaje;
                    this.id_tfg=response.data.id;
                } 
            } 
            this.isLoading = false;
        },
        computed : {
            edad_u : function(){
                if(isEmpty(this.fecha_nacimiento)) return 0;
                return dayjs().diff(this.fecha_nacimiento, 'year');
            },
            erc: function(){

                const estadios=[
                    {
                        start:90,
                        end:99999,
                        title:'ERC en etapa 1',
                        text:'Daño renal leve',
                        class:'badge badge-success badge-marketing'
                    }, 
                    {
                        start:60,
                        end:89.99,
                        title:'ERC en etapa 2',
                        text:'Daño renal leve',
                        class:'badge badge-success badge-marketing'
                    },
                    {
                        start:45,
                        end:59.99,
                        title:'ERC en etapa 3a',
                        text:'Daño renal leve o moderado',
                        class:'badge badge-warning badge-marketing'
                        
                    },
                    {
                        start:30,
                        end:44.99,
                        title:'ERC en etapa 3b',
                        text:'Daño renal leve o moderado',
                        class:'badge badge-warning badge-marketing'
                    },
                    {
                        start:15,
                        end:29.99,
                        title:'ERC en etapa 4',
                        text:'Daño renal moderado o severo',
                        class:'badge badge-orange badge-marketing'
                    },
                    {
                        start:0,
                        end:15.99,
                        title:'ERC en etapa 5',
                        text:'Daño renal severo',
                        class:'badge badge-danger badge-marketing'
                    },
                ]

                if(this.puntaje){
                    const stage = estadios.find(e=>this.puntaje>=e.start && this.puntaje<=e.end);
                    
                    return stage || {
                        start: 0,
                        end: 0,
                        title: 'Clasificación no disponible',
                        class: 'badge badge-light badge-marketing',
                        text: 'sin clasificación'
                    };
                }

                return {
                    start: 0,
                    end: 0,
                    title: 'Cargando clasificacion',
                    class: 'badge badge-light badge-marketing',
                    text:'sin clasificacion'
                };
                
            }
        }
    }
    </script>